import style from "./AllPageBannerPromo.module.scss";
import { useRouter } from "next/router";
import Link from "next/link";
import { HandySvg } from "handy-svg";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../stores/use_global_state";
import * as ga from "../../../lib/ga";
import getCommonLocales from "../../../locales/locales_common";

export const CountdownTimer = ({ close = () => { }, isRu = false }) => {
	const initialTime = 60 * 60 * 1.3;
	const [time, setTime] = useState(initialTime);

	useEffect(
		() => {
			const storedTime = localStorage.getItem("countdownPromoBannerTime");
			const storedTimestamp = localStorage.getItem("countdownPromoBannerTimestamp");

			if (storedTime && storedTimestamp) {
				const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
				const remainingTime = Math.max(0, parseInt(storedTime) - elapsedTime);

				if (remainingTime < 0) {
					localStorage.setItem("promoBannerIsCloseNew", "true");
					close();
					setTime(0);
				}
				setTime(remainingTime);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() => {
			const timer = setInterval(() => {
				setTime((prevTime) => {
					const newTime = prevTime - 1;
					localStorage.setItem("countdownPromoBannerTime", newTime);
					localStorage.setItem("countdownPromoBannerTimestamp", Date.now().toString());

					if (newTime < 0) {
						localStorage.setItem("promoBannerIsCloseNew", "true");
						clearInterval(timer);
						close();
						return 0;
					}

					return newTime;
				});
			}, 1000);

			return () => clearInterval(timer);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const formatTime = (seconds) => {
		const hh = Math.floor(seconds / 3600);
		const mm = Math.floor((seconds % 3600) / 60);
		const ss = seconds % 60;
		return (
			<div className={style.timer}>
				<span data-label={isRu ? "часы" : "hours"}>{String(hh).padStart(2, "0")}</span>:<span data-label={isRu ? "минуты" : "minutes"}>{String(mm).padStart(2, "0")}</span>:
				<span data-label={isRu ? "секунды" : "seconds"}>{String(ss).padStart(2, "0")}</span>
			</div>
		);
	};

	return <>{formatTime(time)}</>;
};

const t = {
	en: { title: "Black Friday Special!", description: "Get a monthly subscription on Black Friday and enjoy 15% more credits every month! Don’t miss out!" },
	ru: { title: "Черная пятница!", description: "Успей оформить месячную подписку и получать каждый месяц на 15% больше кредитов!" }
}

function AllPageBannerPromo() {
	const router = useRouter();
	const { locale } = router;
	const dictionary = getCommonLocales(locale);
	const isRu = locale === "ru";
	const [promoBannerIsOpen, setPromoBannerIsOpen] = useState(false);
	const btn = dictionary["Subscribe"] || "Subscribe";
	const { state } = useContext(GlobalContext);

	const isBlackFridayPeriod = () => {
		const today = new Date();
		const currentYear = today.getFullYear();

		// One month before Black Friday
		const startDate = new Date(currentYear, 10, 10); // November 10st

		// Day after Black Friday (when we stop showing)
		const endDate = new Date(currentYear, 11, 1); // December 1st

		return today >= startDate && today < endDate;
	};

	useEffect(() => {
		if (!isBlackFridayPeriod()) return;

		let affiliateBannerIsClose = sessionStorage.getItem("promoBannerIsCloseNew");
		if (!affiliateBannerIsClose && (state?.userInfo === null || state?.accountData?.plan === "free")) setPromoBannerIsOpen(true);
	}, []);

	useEffect(() => {
		if (!isBlackFridayPeriod()) return;

		if (state?.userInfo !== null && state?.accountData !== null && state?.accountData?.plan !== "free") {
			sessionStorage.setItem("promoBannerIsCloseNew", "true");
			setPromoBannerIsOpen(false);
		}
	}, [state]);

	const close = (event) => {
		event?.stopPropagation();
		sessionStorage.setItem("promoBannerIsCloseNew", "true");
		setPromoBannerIsOpen(false);
	};

	const goToPricingPage = (event) => {
		event?.preventDefault();

		ga.event({ action: `click to explore plans button`, params: { event_callback: () => { router.push({ pathname: "/pricing/" }); } } });
	};

	if (!promoBannerIsOpen) return <></>;

	return (
		<div className={style.wrapper}>
			<div className={`${style.container}`}>
				<button className={style.closeBtn} aria-label="close" onClick={close}>
					<HandySvg width="20" height="20" src={`/editor/close_icon.svg`} />
				</button>
				<div className={style.text}>
					<h4 className={style.title}>{t[isRu ? "ru" : "en"].title}</h4>
					<p className={style.description}>{t[isRu ? "ru" : "en"].description}</p>
				</div>
				<div className={style.timerWrapper}>
					<Link href={"/pricing/"} className={style.button} onClick={goToPricingPage}>
						<span>{btn}</span>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default AllPageBannerPromo;
