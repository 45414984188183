import React, { useState, useEffect } from "react";
import styles from "./BlackFridayPromotion.module.scss";
import ReactDOM from "react-dom";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import getCommonLocales from "../../locales/locales_common";

const t = {
	en: { title: "Black Friday Special!", description: "Get a monthly subscription on Black Friday and enjoy 15% more credits every month! Don’t miss out!" },
	ru: { title: "Черная пятница!", description: "Успей оформить месячную подписку и получать каждый месяц на 15% больше кредитов!" }
}

const BlackFridayPromo = () => {
	const [showModal, setShowModal] = useState(false);
	const router = useRouter();
	const { locale } = router;
	const dictionary = getCommonLocales(locale);
	const btn = dictionary["Subscribe"] || "Subscribe";
	const isRu = locale === "ru";

	const title = t[isRu ? "ru" : "en"].title;
	const description = t[isRu ? "ru" : "en"].description;

	const isBlackFridayPeriod = () => {
		const today = new Date();
		const currentYear = today.getFullYear();

		// One month before Black Friday
		const startDate = new Date(currentYear, 10, 10); // November 10st

		// Day after Black Friday (when we stop showing)
		const endDate = new Date(currentYear, 11, 1); // December 1st

		return today >= startDate && today < endDate;
	};

	const isPricingPage = () => {
		return router?.pathname?.includes("pricing");
	};

	useEffect(() => {

		console.log(isPricingPage())

		if (isPricingPage()) {
			return close();
		}

		// Check if we're in the Halloween period
		if (!isBlackFridayPeriod()) {
			return close();
		}

		// Check if modal was already shown in this session
		const wasShown = sessionStorage.getItem("blackFridayPromoShown");
		console.log("isBlackFridayWasShown");

		if (!wasShown) {
			setShowModal(true);
		}
	}, [router.pathname]);

	const close = () => {
		// Mark modal as shown in session storage
		sessionStorage.setItem("blackFridayPromoShown", "true");
		setShowModal(false);
	};

	// Don't render anything if modal shouldn't be shown
	if (!showModal) {
		return null;
	}

	return ReactDOM.createPortal(
		<div className={`${styles.modalWrapperPortal}`}>
			<div onClick={close} className={styles.modalCloseBg} />
			<div className={styles.container}>
				<button className={styles.close} onClick={close}></button>

				<Image className={styles.scene} width={680} height={446} src="/promotion/blackFriday.png" alt={"Halloween home"} />
				<div className={styles.content}>
					<div className={styles.text}>
						<h2 className={styles.title}>{title}</h2>
						<p className={styles.subtitle}>{description}</p>
					</div>
					<Link href="/pricing/" className={styles.discoverButton}>
						{btn}
					</Link>
				</div>
			</div>
		</div>,
		document.getElementById("portal")
	);
};

export default BlackFridayPromo;
